import { createSlice } from "@reduxjs/toolkit";

const supportSlice = createSlice({
    name: "support",
    initialState: {
        support: [],
        sidebarChats: [],
        selectedMessages: [],
        chatData: [],
        contacts: [],
        tickets: [],
        isTyping: true,
        selectedTicket: null, // Changed to null
        showChatBox: false
    },
    reducers: {
        setShowChatBox: (state, action) => {
            state.showChatBox = action.payload;
        },
        setIstyping: (state, action) => {
            state.isTyping = action.payload
        },
        setSelectedTicket: (state, action) => {
            state.selectedTicket = action.payload;
        },
        setTicket: (state, action) => {
            state.tickets = action.payload;
        },
        updateTicket: (state, action) => {
            state.tickets.push(action.payload);
        },
        updateSelectedTicket: (state, action) => {
            state.selectedTicket = action.payload;
        },
        deleteTicket: (state, action) => {
            const id = action.payload;
            state.tickets = state.tickets.filter(ticket => ticket.id !== id);
        },
        setContacts: (state, action) => {
            state.contacts = action.payload;
        },
        setSidebarChats: (state, action) => {
            state.sidebarChats = action.payload;
        },
        updateChatSide: (state, action) => {
            const newChat = action.payload;
            const newMessage = newChat.messages[0];
            const existingChatIndex = state.sidebarChats.findIndex(chat =>
                chat.id === newMessage.receiverId || chat.id === newMessage.senderId
            );

            if (existingChatIndex > -1) {
                state.sidebarChats[existingChatIndex] = {
                    ...state.sidebarChats[existingChatIndex],
                    messages: [...state.sidebarChats[existingChatIndex].messages, ...newChat.messages]
                };
            } else {
                state.sidebarChats.push(newChat);
            }
        },
        addChats: (state, action) => {
            state.chatData.push(action.payload);
        },
        setChatData: (state, action) => {
            state.chatData = action.payload;
        },
        removeChat: (state, action) => {
            state.chatData = state.chatData.filter(chat => chat.id !== action.payload.id);
        },
        updateLastSeen: (state, action) => {
            const { id, lastSeen, online } = action.payload;

            const sidebarChatIndex = state?.sidebarChats?.findIndex(chat => chat.id === id);
            if (sidebarChatIndex > -1) {
                state.sidebarChats[sidebarChatIndex] = {
                    ...state.sidebarChats[sidebarChatIndex],
                    lastSeen,
                    online,
                };
            }
            state.chatData = {
                ...state.chatData,
                lastSeen,
                online
            }
        },

        markMessagesAsRead: (state, action) => {
            const { readMessages } = action.payload;

            state.sidebarChats.forEach(chat => {
                chat.messages.forEach(message => {
                    if (readMessages.some(unreadMessage => unreadMessage.id === message.id)) {
                        console.log(`Marking message as read in sidebarChats: ${message.id}`);
                        message.read = true;
                    }
                });
            });

            if (state.chatData.length !== 0) {
                state.chatData.messages.forEach(message => {
                    if (readMessages.some(unreadMessage => unreadMessage.id === message.id)) {
                        console.log(`Marking message as read in chatData: ${message.id}`);
                        message.read = true;
                    }
                });
            }
        },
    },
});

export const {
    setChatData,
    setShowChatBox,
    removeChat,
    setSidebarChats,
    updateChatSide,
    addChats,
    setContacts,
    updateLastSeen,
    markMessagesAsRead,
    updateTicket,
    setTicket,
    deleteTicket,
    updateSelectedTicket,
    setSelectedTicket,
    setIstyping,
} = supportSlice.actions;

export default supportSlice.reducer;

export const selectSelectedTicket = (state) => state.support.selectedTicket;
export const selectTicket = (state) => state.support.tickets;
export const selectIsTyping = (state) => state.support.isTyping;
export const selectSupport = (state) => state.support.support;
export const selectShowChatBox = (state) => state.support.showChatBox;
export const selectChatData = (state) => state.support.chatData;
export const selectSidebarChats = (state) => state.support.sidebarChats;
export const selectContacts = (state) => state.support.contacts;
