import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "queries",
    initialState: {
        queries: [],
        contractQueries: [],
        ongoingQueries: [], // Fixed spelling to camelCase
        completedQueries: [],
        selectedQueries: {},
        viewDetails: false,
        searchTerm: "",
        currentPage: 1,
        filteredQueries: [],
    },
    reducers: {
        setQueries: (state, action) => {
            state.queries = action.payload;
        },
        setFilteredQueries: (state, action) => {
            state.filteredQueries = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setCompletedQueries: (state, action) => {
            state.completedQueries = action.payload;
        },
        setViewDetails: (state, action) => {
            state.viewDetails = action.payload;
        },
        setOngoingQueries: (state, action) => {
            state.ongoingQueries = action.payload;
        },
        setNewMilestone: (state, action) => {
            const { queriesId, milestone } = action.payload;
            // Map through the ongoing queries to create a new state
            state.ongoingQueries = state.ongoingQueries.map(query => {
                if (query.id === queriesId) {
                    return {
                        ...query,
                        milestones: [
                            ...query.milestones,
                            {
                                ...milestone,
                                payments: [] // Initialize payments as an empty array
                            }
                        ]
                    };
                }
                return query;
            });
        },
        setContractQueries: (state, action) => {
            state.contractQueries = action.payload;
        },
        setSelectedQueries: (state, action) => {
            state.selectedQueries = action.payload;
        },
        addQuery: (state, action) => {
            state.queries.push(action.payload);
        },
        updateQuery: (state, action) => {
            const { id, updatedData } = action.payload;
            const queryIndex = state.queries.findIndex(query => query.id === id);
            if (queryIndex !== -1) {
                state.queries[queryIndex] = { ...state.queries[queryIndex], ...updatedData };
            }
        },
        deleteQuery: (state, action) => {
            const id = action.payload;
            state.queries = state.queries.filter(query => query.id !== id);
        },
        deleteOngoingQuery: (state, action) => {
            const id = action.payload;
            state.ongoingQueries = state.ongoingQueries.filter(query => query.id !== id);
        },
        toggleBookmarkInQuery: (state, action) => {
            const { queriesId, bookmark } = action.payload;

            // Create a new queries array to ensure immutability
            state.queries = state.queries.map(query => {
                if (query.id === queriesId) {
                    const bookmarkExists = query.bookmarks.some(b => JSON.stringify(b) === JSON.stringify(bookmark));
                    return {
                        ...query,
                        bookmarks: bookmarkExists ? [] : [...query.bookmarks, bookmark],
                    };
                }
                return query;
            });
        },
        setBookmarksForQuery: (state, action) => {
            const { queriesId, bookmarks } = action.payload;
            const query = state.queries.find(query => query.id === queriesId);
            if (query) {
                query.bookmarks = bookmarks;
            }
        },
        setVerifyContract: (state, action) => {
            const id = action.payload;
            state.contractQueries = state.contractQueries.filter(query => query.id !== id);
        },
    },
});

export const {
    setQueries,
    setOngoingQueries,
    setCompletedQueries,
    addQuery,
    setSearchTerm,
    setBookmarksForQuery,
    toggleBookmarkInQuery,
    updateQuery,
    deleteQuery,
    setContractQueries,
    setVerifyContract,
    setViewDetails,
    deleteOngoingQuery,
    setSelectedQueries,
    setFilteredQueries,
    setCurrentPage,
    setNewMilestone,
} = authSlice.actions;

export default authSlice.reducer;

export const selectViewDetails = (state) => state.queries.viewDetails;
export const selectOngoingQueries = (state) => state.queries.ongoingQueries;
export const selectQueries = (state) => state.queries.queries;
export const selectCompletedQueries = (state) => state.queries.completedQueries;
export const selectContractQueries = (state) => state.queries.contractQueries;
export const selectSelectedQueries = (state) => state.queries.selectedQueries;
export const selectSearchTerm = (state) => state.queries.searchTerm;
export const selectFilteredQueries = (state) => state.queries.filteredQueries;
export const selectCurrentPage = (state) => state.queries.currentPage;
