import { createSlice } from "@reduxjs/toolkit";

const DisputeSlice = createSlice({
    name: "dispute",
    initialState: {
        disputes: [],
        disputeMessages: []
    },
    reducers: {
        setDisputes: (state, action) => {
            state.disputes = action.payload;
        },
        setDisputeMessages: (state, action) => {
            state.disputeMessages = action.payload;
        },
        addDisputeMessage: (state, action) => {
            // Adds a new message to the disputeMessages array
            state.disputeMessages.push(action.payload);
        }
    },
});

export const {
    setDisputes,
    setDisputeMessages,
    addDisputeMessage
} = DisputeSlice.actions;

export default DisputeSlice.reducer;

export const selectDisputes = (state) => state.dispute.disputes;
export const selectDisputeMessages = (state) => state.dispute.disputeMessages;
