"use client"; // This file contains only client-side logic

import { Provider } from "react-redux";
import { persistor, store } from "@/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { useEffect, useState } from "react";
import { SocketProvider } from "./SocketContext.js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

export default function ClientProvider({ children }) {
  const [mounted, setMounted] = useState(false);

  // Ensures PersistGate is only used on the client-side
  useEffect(() => {
    setMounted(true);
  }, []);

  const initialOptions = {
    clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };

  return (
    <Provider store={store}>
      <PayPalScriptProvider options={initialOptions}>
        <SocketProvider>
          {mounted ? (
            <PersistGate loading={null} persistor={persistor}>
              {children}
            </PersistGate>
          ) : (
            children
          )}
        </SocketProvider>
      </PayPalScriptProvider>

    </Provider>
  );
}

