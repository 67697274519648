import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { apiSlice } from "../services/api";
import AuthReducers from "../reducers/AuthReducers";
import FreelancerReducer from "../reducers/FreelancerReducer";
import ProjectReducer from "../reducers/ProjectReducer";
import clientsReducer from "../reducers/clientsReducer";
import proposalReducer from "../reducers/proposalReducer";
import categoriesReducer from "../reducers/categoriesReducer";
import supportReducer from "../reducers/supportReducer";
import memberShipReducer from "../reducers/memberShipReducer";
import reviewReducer from "../reducers/reviewReducer";
import NotificationReducer from "../reducers/NotificationReducer";
import WalletReducer from "../reducers/WalletReducer";
import MpesaReducer from "../reducers/MpesaReducer";
import postReducer from "../reducers/postReducer";
import messagesReducer from "../reducers/messagesReducer";
import paymentMethodsReducer from "../reducers/PaymentMethodReducer";
import milestonesReducer from "../reducers/MilestoneReducer";
import PayoutReducer from "../reducers/PayoutReducer";
import queriesReducer from  "../reducers/QueriesReducer";
import disputeReducer from "../reducers/disputeReducer";

// Persist configuration for the auth reducer
const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["token", "activeRole", "inactiveRoles", "projectId", "isLoggedIn", "roles", "user", "refreshToken", "email", "otp"],
  stateReconciler: autoMergeLevel2,
};


const projectPersistConfig = {
  key: "project",
  storage,
  whitelist: ["selectedProject"],
  stateReconciler: autoMergeLevel2,
};



// Root reducer combining all reducers
const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, AuthReducers),
  //   other reducer
  [apiSlice.reducerPath]: apiSlice.reducer,
  freelancer: FreelancerReducer,
  project: persistReducer(projectPersistConfig, ProjectReducer),
  client: clientsReducer,
  proposal: proposalReducer,
  category: categoriesReducer,
  support: supportReducer,
  review: reviewReducer,
  notifications: NotificationReducer,
  membership: memberShipReducer,
  wallet: WalletReducer,
  mpesa: MpesaReducer,
  post: postReducer,
  dispute: disputeReducer,
  payout: PayoutReducer,
  milestone: milestonesReducer,
  paymentMethods: paymentMethodsReducer,
  messages: messagesReducer,
  queries: queriesReducer
});

// Configure the store with the root reducer and middleware
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializableCheck
    }).concat(apiSlice.middleware), // Add thunk if needed
  devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools only in development
});

// Configure the persistor
export const persistor = persistStore(store);
