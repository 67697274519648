import { createSlice } from "@reduxjs/toolkit";

const walletSlice = createSlice({
    name: "wallet",
    initialState: {
        wallets: [],
        companies: null,
    },
    reducers: {
        setWallets: (state, action) => {
            state.wallets = action.payload;
        },
        setCompanies: (state, action) => {
            state.companies = action.payload;
        },

    },
});

export const {
    setWallets,
    setCompanies,
} = walletSlice.actions;

export default walletSlice.reducer;

export const selectWallets = (state) => state.wallet.wallets;
export const selectCompanies = (state) => state.wallet.companies;
