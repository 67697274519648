import { createSlice } from "@reduxjs/toolkit";

// Redux slice for managing notifications
const notificationsSlice = createSlice({
    name: "notifications", // Name should be plural to indicate it's managing multiple notifications
    initialState: {
        allNotifications: [], // Renamed from 'notifications' to 'allNotifications' for clarity
        unExpiredNotifications: [], // Kept as is, but you can clarify the naming further if necessary
    },
    reducers: {
        // Set  notifications
        setNotifications: (state, action) => {
            state.allNotifications = action.payload;
        },
        
        // Set only unexpired notifications
        setUnExpiredNotifications: (state, action) => {
            state.unExpiredNotifications = action.payload;
        },
        
        // Add a new notification to the list
        addNotification: (state, action) => {
            state.allNotifications.push(action.payload);
        },
        
        // Update a specific notification based on id
        updateNotification: (state, action) => {
            const { id, updatedData } = action.payload;
            const notificationIndex = state.allNotifications.findIndex(notification => notification.id === id);
            if (notificationIndex !== -1) {
                state.allNotifications[notificationIndex] = {
                    ...state.allNotifications[notificationIndex],
                    ...updatedData
                };
            }
        },
        
        // Delete a notification by id
        deleteNotification: (state, action) => {
            const id = action.payload;
            state.allNotifications = state.allNotifications.filter(notification => notification.id !== id);
        },
    },
});

// Exporting the actions from the slice
export const {
    setNotifications,  // renamed from 'setNotifications'
    addNotification,
    updateNotification,
    deleteNotification,
    setUnExpiredNotifications
} = notificationsSlice.actions;

// Default export of the reducer to be used in the store
export default notificationsSlice.reducer;

// Selectors for accessing the state
export const selectNotifications = (state) => state.notifications.allNotifications;
export const selectUnExpiredNotifications = (state) => state.notifications.unExpiredNotifications;
